#translation {
	background-color: lightgray;
	width: 100%;
	height: auto;
	display: block;
	position: fixed;
	top: 0;
	z-index: 210000;
	#country {
		display: flex;
		float: right;
		padding-top: 3px;
		padding-bottom: 3px;
		// position: sticky;
		// bottom: 10px;
		// right: 5px;
		// width: 112px;
		// z-index: 99999;
		// margin-top: -40px;
		img {
			width: 20px;
			height: auto;
		}
		button {
			// background-color: #030303;
			// border: 1px solid white;
			border-radius: 10px;
		}
		.active {
			opacity: 1;
		}
		.deactive {
			opacity: 0.5;
		}
	}
}
