#ribbon {
	position: fixed;
	top: 98.5px;
	z-index: 999;
	width: 100%;
	max-height: 51px;
	background-color: $primary-light-color;

	a {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		display: block;
		padding-left: 20px;
		padding-right: 20px;
	}

	.hero-ribbon {
		background-color: $primary-light-color;
		color: $primary-dark-color;
		font-size: 18px;
		line-height: 37px;
	}
}

.bg-illust-wrapper {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 0;
}

#home-hero {
	height: 100vh;
	min-height: 886px;
	display: flex;
	justify-content: center;
	justify-items: center;
	align-items: center;
	position: relative;
	.container-fluid {
		z-index: 1;
	}

	.hero-headline {
		font-family: Poppins, 'Noto Sans KR', sans-serif;
		font-style: normal;
		font-weight: 500;
		font-size: 40px;
		line-height: 40px;
		/* or 100% */

		@media (max-width: 576px) {
			font-size: 32px;
			line-height: 36px;
		}

		text-transform: capitalize;

		color: $dark;

		.hero-headline-red {
			color: $primary-color;
		}
	}

	.hero-subhead {
		font-size: 12px;
		line-height: 18px;
		color: $dark;
	}

	.hero-description {
		color: $para-color;
		font-size: 16px;
	}

	.jump-button-wrapper {
		position: absolute;
		bottom: 0px;
		padding-bottom: 40px;
		width: calc(100% - 30px);
		cursor: pointer;
	}

	// .hero-btn-container {
	// 	background-color: black;
	// 	width: 100%;
	// }
	.button-wrapper {
		width: 100%;
		margin: 0px;
		padding: 0 !important;
	}
	.bg-illust-wrapper {
		.top-right-large-spill {
			width: 842px;
			height: 691px;
			background-size: 842px 691px;
			background-repeat: no-repeat;
			background-image: url('/icons/home-hero-bg-large-spill.png');
			position: absolute;
			top: 0;
			right: 0;
		}

		.left-bottom-small-spill {
			width: 157px;
			height: 230px;
			background-size: 157px 230px;
			background-repeat: no-repeat;
			background-image: url('/icons/home-hero-bg-small-spill.png');
			position: absolute;
			bottom: 0;
			left: 0;
		}
	}

	.hero-1-btn,
	.hero-2-btn {
		width: 200px;
		span {
			font-size: 1.2rem;
		}
	}
	@media (max-width: 768px) {
		.hero-btn-container {
			width: 100% !important;
			display: flex;
			flex-direction: column;
			align-items: center;
			.hero-1-btn {
				width: 200px;
				margin: 0px !important;
			}
			.hero-2-btn {
				width: 200px;
				margin: 0;
			}
		}
	}
	@media (max-width: 576px) {
		.hero-btn-container {
			.hero-1-btn {
				width: 100%;
			}
			.hero-2-btn {
				width: 100%;
			}
		}
	}
}

#home-about-us {
	.about-us-headline {
		font-family: Montserrat;
		font-style: normal;
		font-weight: 600;
		font-size: 32px;
		line-height: 39px;
		margin-bottom: 0;
		color: $dark;
	}

	.about-us-subhead {
		font-family: Poppins, 'Noto Sans KR', sans-serif;
		font-style: normal;
		font-weight: 500;
		font-size: 24px;
		line-height: 36px;
		color: $primary-color;
	}

	.circular-icon {
		padding: 10px 11px 10px 15px;
	}
}

#home-invest {
	position: relative;
	display: block;
	margin: auto;

	.container-fluid {
		z-index: 1;
	}

	@media (min-width: 768px) {
		display: block;
	}

	.invest-wrapper {
		text-align: center;
		z-index: 1;

		.carousel {
			.carousel-control-prev,
			.carousel-control-next {
				visibility: hidden;
			}
		}

		.carousel-custom-control-wrapper {
			margin-top: 20px;
			text-align: center;
			display: inline-flex;
			.carousel-custom-control-prev {
				margin-right: 12.5px;
				border: unset;
				background-color: transparent;
				padding-right: 0;
				outline: unset !important;
				box-shadow: unset !important;
			}

			.carousel-custom-control-next {
				margin-left: 12.5px;
				border: unset;
				padding-left: 0;
				background-color: transparent;
				outline: unset !important;
				box-shadow: unset !important;
			}
		}

		.invest-card-wrapper {
			.invest-card-row {
				.invest-card-col {
					display: none;
					max-width: 340px;
					margin: auto;

					&:nth-child(1) {
						display: block;
					}

					@media (min-width: 1200px) {
						&:nth-child(4),
						&:nth-child(3),
						&:nth-child(2),
						&:nth-child(1) {
							display: block;
							max-width: unset;
						}
					}

					@media (min-width: 992px) {
						&:nth-child(3),
						&:nth-child(2),
						&:nth-child(1) {
							display: block;
							max-width: unset;
						}
					}

					@media (min-width: 768px) {
						&:nth-child(2),
						&:nth-child(1) {
							display: block;
							max-width: unset;
						}
					}
				}
			}
			.invest-card-individual {
				padding: 16px;
				background: #ffffff;
				box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
				text-align: left;
				position: relative;

				.apv {
					position: absolute;
					left: 16px;
					top: 25px;
					background-color: #03946d;
					color: white;
					padding: 2px 8px;
					border-top-right-radius: 5px;
					border-bottom-right-radius: 5px;
				}
				.invest-property-img {
					object-fit: cover;
				}

				.invest-card-subhead {
					display: block;
					color: $para-color;
				}

				.invest-card-head {
					display: block;
					font-family: Poppins, 'Noto Sans KR', sans-serif;
					font-style: normal;
					font-weight: 500;
					font-size: 18px;
					line-height: 27px;
					margin-top: 8px;
					color: $dark;
				}

				.invest-line-wrapper {
					margin-top: -3px;
				}

				.invest-card-detail {
					font-size: 12px;
					line-height: 18px;
					color: $para-color;

					.invest-loantype-nowrap {
						white-space: nowrap;
					}
				}

				.invest-amt-wrapper {
					position: absolute;
					right: 16px;
					bottom: 16px;
					text-align: right;

					.invest-amt-key {
						display: block;
						font-size: 12px;
						line-height: 18px;
						text-transform: uppercase;
						color: $para-color;
					}

					.invest-amt-val {
						font-weight: bold;
						font-size: 16px;
						line-height: 24px;
						text-transform: uppercase;
						color: $para-color;
					}
				}
			}

			.no-new-listing {
				display: flex;
				justify-content: center;
				align-items: center;
				min-height: 344.5px;

				.no-new-listing-text {
					font-weight: 600;
					color: $para-color;
					opacity: 0.4;
				}
			}
		}
	}

	.bg-illust-wrapper {
		.left-waffle {
			width: 157px;
			height: 209px;
			background-size: 157px 209px;
			background-repeat: no-repeat;
			background-image: url('/icons/home-inventory-waffle-left.png');
			position: absolute;
			top: 20px;
			left: 0;
		}

		.right-waffle {
			width: 106px;
			height: 212px;
			background-size: 106px 212px;
			background-repeat: no-repeat;
			background-image: url('/icons/home-inventory-waffle-right.png');
			position: absolute;
			bottom: 0;
			right: 0;
		}
	}
}

#home-faq {
	.faq-wrapper {
		text-align: center;
		.faq-headline {
			font-family: Montserrat;
			font-style: normal;
			font-weight: 600;
			font-size: 32px;
			line-height: 39px;
			color: $dark;
		}

		.faq-card-wrapper {
			text-align: left;
			background: #ffffff;
			border: unset;
			margin-top: 1px;
			border-radius: unset;
			overflow: visible;
			box-shadow: unset;

			.faq-card {
				padding: 16px;
				background: #ffffff;
				.faq-header {
					font-family: Poppins, 'Noto Sans KR', sans-serif;
					font-style: normal;
					font-weight: 500;
					font-size: $font-size;
					line-height: 21px;
					color: #000000;
					margin: 0;
				}
			}
		}

		.faq-collapse {
			text-align: left;
			background: #f6f6f6;
			padding: 16px 30px;
			margin-top: 1px;

			.faq-answer {
				font-family: Poppins, 'Noto Sans KR', sans-serif;
				font-style: normal;
				font-weight: normal;
				font-size: $font-size;
				line-height: 21px;
				color: $para-color;
			}
		}
	}

	.faq-btn-wrapper {
		text-align: center;
	}
}

#home-contact-us {
	position: relative;

	.container-fluid .row {
		z-index: 1;
	}

	.contact-us-map {
		width: 100%;
		height: 500px;
		border: 10px solid #ffffff;
		filter: drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.2));
	}

	.contact-us-headline {
		font-family: Montserrat;
		font-style: normal;
		font-weight: 600;
		font-size: 32px;
		line-height: 39px;
		margin-bottom: 0;
		color: $dark;
	}

	.contact-us-subhead {
		font-family: Poppins, 'Noto Sans KR', sans-serif;
		font-style: normal;
		font-weight: 500;
		font-size: 24px;
		line-height: 36px;
		color: $primary-color;
	}

	.bg-illust-wrapper {
		.left-waffle {
			width: 145.5px;
			height: 145.5px;
			background-size: 145.5px 145.5px;
			background-repeat: no-repeat;
			background-image: url('/icons/home-contact-us-waffle-left.png');
			position: absolute;
			top: 20px;
			left: 0;
		}

		.right-waffle {
			width: 186px;
			height: 201px;
			background-size: 186px 201px;
			background-repeat: no-repeat;
			background-image: url('/icons/home-contact-us-waffle-right.png');
			position: absolute;
			bottom: 0;
			right: 0;
		}
	}
}
