$dark: #303030;

#how-it-works {
	.bg-illust-wrapper {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 3;
	}
	.howItWorks-BG {
		width: 1124px;
		height: 394px;
		background-size: 1124px 394px;
		background-repeat: no-repeat;
		background-image: url('./../components/HowItWorks/background.png');
		position: absolute;
		right: 0;
	}
	.main-title {
		text-align: center;
		color: $dark;
	}
	.arrow-box {
		display: flex;
		width: 100%;
		flex-direction: column;
		align-items: center;
	}
	.downArrow {
		width: 47.63px;
		filter: brightness(95%);
	}

	// list
	.container {
		margin-bottom: 100px;
	}
	ol {
		list-style: none;
	}

	.list-row {
		padding: 20px 0;
		.step-card {
			display: flex;
			margin-bottom: 60px;
			.step-img {
				width: 132px;
			}
			.down-mobile {
				width: 27.44px;
				height: 70.26px;
				display: none;
			}
			.step-info {
				display: flex;
				flex-direction: column;
				margin-top: 40px;
				width: 100%;
				margin-left: 15px;
				.title {
					color: $dark;
					word-break: keep-all;
				}
				p {
					margin-top: 7px;
					color: $para-color;
				}
			}
		}
		.row-space {
			// flex: 4.4;
			.arrow-img {
				width: 178.82px;
				height: 159.5px;
				margin-top: 100px;
				position: absolute;
				filter: brightness(95%);
			}
		}
		.row-space-odd {
			.arrow-img-odd {
				padding-left: 20px;
			}
		}
		.row-space-even {
			display: flex;
			justify-content: flex-end;
			.arrow-img-even {
				margin-right: 20px;

				padding-right: 20px;
			}
		}
	}

	@media (max-width: 768px) {
		.arrow-box {
			align-items: flex-start;
		}
		.main-title {
			text-align: left;
		}
		.downArrow {
			display: none;
		}

		ol {
			padding: 0;
		}
		.list-row {
			flex-direction: column;
			padding: 0;
			.step-card {
				.down-mobile {
					width: 27.44px;
					height: 70.26px;
					display: block;
					margin: 20px 0 0 23px;
					position: absolute;
				}
				.step-img {
					width: 80px;
				}
				.step-info {
					margin-top: 40px;
					margin-bottom: 10px;
				}
			}

			.row-space {
				width: 100%;
				.img-box {
					position: absolute;
					margin-left: 50px;
				}
				.arrow-img {
					display: none;
				}
			}
		}
	}

	@media (max-width: 768px) {
		.mt-71p {
			margin-top: 30px;
		}
		.mobile-chart-margin {
			margin-top: 50px;
		}
	}
}
