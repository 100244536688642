footer {
	border-top: 1px solid #dddddd;
	background-color: #f5f5f5;
	a {
		color: $para-color;
		&:hover {
			color: $primary-hover-color;
			text-decoration: unset;
		}
	}
	#footer {
		background-color: #f5f5f5;
	}
	#copyright {
		background-color: white;
	}
	#socialmedia {
		padding-top: 21px;
		padding-bottom: 21px;
		margin-bottom: 14px;
		border-bottom: 1px solid #dddddd;
		#instagram {
			margin-left: 21px;
		}
	}
	#footnote {
		span {
			font-style: italic;
			font-size: 10px;
			line-height: 15px;
		}
		.asterisk {
			color: red;
		}
	}
	.no-bullet {
		li {
			margin-bottom: 8px;
			span {
				margin-left: 16px;
			}
		}
	}
}

#copyright {
	padding-top: 11.5px;
	padding-bottom: 11.5px;
	span {
		font-size: 12px;
		line-height: 18px;
	}
}
