#borrower-form {
	min-height: calc(100vh - 85px - 391px);
	h3 {
		background-color: black;
		color: white;
		display: inline-block;
		padding: 0 5px;
		font-size: 20px;
		line-height: 24px;
		margin-top: 20px;
		text-transform: capitalize;
	}
}


#borrower-contact-form {
	.radio-button {
		padding: 10px 20px;
		background: #03946d;
		border: 1px solid #03946d;
		box-sizing: border-box;
		color: white !important;
		&:first-child {
			border-top-left-radius: 6px;
			border-bottom-left-radius: 6px;
			margin-right: 1px;
		}
		&:last-child {
			border-top-right-radius: 6px;
			border-bottom-right-radius: 6px;
			margin-left: 1px;
		}

		&:hover, &:focus, &.radio-button-selected {
			color: #03946d !important;
			background-color: #d4e9e2;
			transform: scale(1.02);
			box-shadow: none;
		}
	}
}

#welcome-page {
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;

	.welcome-title {
		word-break: keep-all;
		word-wrap: normal;
		font-size: 2.5rem;
		line-height: 1.5;

		@media screen and (max-width: 991px) {
			font-size: 2.3rem;
			line-height: 1.5;
		}
	}

	.welcome-text {
		word-break: keep-all;
		word-wrap: normal;
		font-size: 18px;
		line-height: 24px;

		@media screen and (max-width: 991px) {
			font-size: 16px;
			line-height: 21px;
		}
	}

	.service-email {
		word-break: keep-all;
		word-wrap: normal;
		font-weight: 500;
		color: $primary-color;
	}

	.solid-small {
		padding: 1rem 1.5rem;
	}

	.strong {
		font-weight: 600;
	}

	.summary {
		background-color: #f6f9fc;
	}

	.card-img-top.form-logo {
		width: 400px;
		height: auto; 
	}
}

#membership {
	.solid-small {
		padding: 1rem 1.5rem;
	}
}
