#about-us {
	.bg-illust-wrapper {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		// z-index: 1030;
		.AboutUs-top-spill {
			width: 1146px;
			height: 515px;
			background-size: 1146px 515px;
			background-repeat: no-repeat;
			background-image: url('/icons/AboutUs-top.png');
			position: absolute;
			top: 0;
			left: 0;
		}
		.AboutUs-bottom-spill {
			width: 139px;
			height: 192px;
			background-size: 139px 192px;
			background-repeat: no-repeat;
			background-image: url('/icons/AboutUs-bottom.png');
			position: absolute;
			top: 400px;
			right: 0;
		}
	}
	@media (max-width: 991px) {
		.bg-illust-wrapper {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 296px;
			// z-index: 1030;
			.AboutUs-top-spill {
				width: 120%;
				height: 100%;
				background-size: 100% 296px;
				background-repeat: no-repeat;
				background-image: url('/icons/AboutUs-top.png');
				position: absolute;
				top: 0;
				right: 600;
			}
			.AboutUs-bottom-spill {
				display: none;
			}
		}
	}
}

#loan-products {
	.badge {
		font-size: $font-size;
		margin-right: 5px;
		margin-bottom: 5px;
		background-color: $primary-light-color;
		padding: 5px 8px;
		font-weight: 400;
		color: black;
	}
}
