#post {
	.post-wrapper {
		width: 100%;

		img {
			width: 100%;
			height: auto;
		}
	}
	.category {
		border: 1px solid white;
		padding: 5px;
		margin-right: 10px;
	}
	.post_details {
		span {
			padding-right: 1em;
			padding-left: 1em;
		}
		.date {
			border-right: 1px solid white;
			border-left: 1px solid white;
		}
	}
}
