#dashboard-board {
	@media (min-width: 576px) {
		width: calc(100% - 52px) !important;
	}
	.MuiTableCell-root {
		font-size: $font-size;
		font-family: Poppins, 'Noto Sans KR', sans-serif;
		font-weight: normal;
		font-style: normal;
		line-height: 21px;
	}
	.MuiTableCell-paddingCheckbox {
		padding: 0 4px;
	}
	.checkbox-white {
		.MuiCheckbox-root {
			color: white !important;
		}
	}
	span {
		font-size: $font-size;
		font-family: Poppins, 'Noto Sans KR', sans-serif;
		font-weight: normal;
		font-style: normal;
		line-height: 21px;
	}
	th {
		background-color: $primary-color;
		border-right: 5px solid white;
		&:last-child {
			border-right: unset;
		}
	}
	td {
		border-right: 5px solid white;
		&:last-child {
			border-right: unset;
		}
		border-bottom: unset;
	}
	@media (min-width: 600px) {
		.toolbar {
			min-height: 54px !important;
		}
	}
	@media (min-width: 0px) and (orientation: landscape) {
		.toolbar {
			min-height: 48px !important;
		}
	}

	.toolbar {
		border-bottom: 1px solid #dddddd;
		min-height: 54px !important;
		padding-left: 0;
	}

	.MuiSwitch-colorSecondary.Mui-checked {
		color: $primary-color;
	}
	.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
		background-color: $primary-color;
		opacity: 0.5;
	}
}
