@import '~bootstrap/scss/bootstrap';
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap');

$primary-color: #b42025;
$primary-hover-color: #9c1c20;
$pink: #f6efef;
$light: #f6f6f6;
$para-color: #666666;
$black: #1b1b1b;
$dark: #303030;
$body-back: #fafafa;
$font-size: 14px;
$font-retina-size: 125%;

$primary-color: #03946d;
$primary-hover-color: #008248;
$primary-light-color: #d4e9e2;
// $light: #f6f6f6;
// $para-color: #666666;
$black: #2e2f2f;
$primary-dark-color: #1e3932;

@import './Home.scss';
@import './global.scss';
@import './nav.scss';
@import './Investors.scss';
@import './Footer.scss';
@import './Dashboard.scss';
@import './AboutUs.scss';
@import './Board.scss';
@import './Borrower.scss';
@import './SingleItem.scss';
@import './HowItWorks.scss';
@import './LoanParameters.scss';
@import './Blog.scss';
@import './Post.scss';
@import './Translation.scss';
