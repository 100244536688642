#dashboard {
	// width: calc(100% - 270px);
	width: 100%;
	.m-26px {
		@media (min-width: 576px) {
			margin: 26px;
		}
	}
	.form-label {
		margin-bottom: 0;
	}
	.highlight {
		background-color: black;
		color: white;
		padding: 0 5px;
		text-transform: uppercase;
		font-size: 18px;
		line-height: 23px;
	}
	#dropdown-item-button {
		text-transform: capitalize;
		border: 1px solid #dddddd;
		background-color: $body-back;
		box-sizing: border-box;
		border-radius: 3px;
		color: $para-color;
		font-size: $font-size;
		line-height: 21px;
		width: 100%;
		text-align: left;
	}
	.dropdown-item {
		text-transform: capitalize;
	}
	.dropdown-toggle::after {
		top: 1.25em;
	}

	// table
	.MuiToolbar-root {
		background-color: white !important;
		color: black i !important;
	}

	// checkbox
	.MuiCheckbox-colorSecondary.Mui-checked {
		color: #03946d;
	}
	// // c2dbff
	// // row checked
	.Mui-selected {
		background-color: #f5f5f5 !important;
	}
	.MuiTypography-root {
		color: black;
	}
	// // odd row color
	// .bg-body-back {
	// 	background-color: gray;
	// }
	// // even row color
	// .bg-light-dark {
	// 	background-color: white !important;
	// }
}

#dashboard-board {
	// table head
	// th {
	// 	background-color: black !important;
	// }

	// .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
	// 	background-color: black !important;
	// 	opacity: 0.5 !important;
	// }

	// .MuiSwitch-colorSecondary.Mui-checked {
	// 	color: black !important;
	// }
	.primary-button {
		border-radius: 0;
	}

	.primary-button:focus {
		border-radius: 0;
	}
}

#dashboard-form {
	background-color: white;
	padding: 16px 19px 20px 16px;
	.header {
		border-bottom: 1px solid #dddddd;
	}
	input.form-control {
		margin-top: 10px;
		background-color: $body-back;
	}
	button {
		padding: 8px 16px;
	}

	.input-group-text {
		margin-top: 10px;
	}

	h3 {
		background-color: black;
		color: white;
		display: inline-block;
		padding: 0 10px;
		font-size: 20px;
		margin-top: 20px;
	}
	h2 {
		background-color: $primary-color;
		color: white;
		display: inline-block;
		padding: 0 24px;
		font-size: 28px;

		margin-top: 20px;
	}
	.radioBtns {
		height: 34px;
	}

	#dropdown-item-button {
		height: 34px;
		padding: 4px 16px !important;
	}
}
